import React from "react"

import { ContentPage } from "components/ContentPage"
import { AnyQuestions } from "components/AnyQuestions"
import { Table } from "components/Table"

import IndyLink from "components/IndyLink"

import "./privacy-policy.scss"

export default function PrivacyPolicy() {
  return (
    <ContentPage Title="Privacy Policy" path="/privacy-policy">
      <p>
        Mashoom Ltd. ("us", "we", or "our") operates the www.mashoom.co.uk
        website and it's associated apps / deployments (the "Service"). These
        are listed as follows:
      </p>
      <ul>
        <li>The website hosted at www.mashoom.co.uk/</li>
        <li>The web app hosted at www.mashoom.co.uk/user/</li>
        <li>The API playground hosted at api-playground.mashoom.co.uk</li>
        <li>The Outlook Add-in named "Mashoom for Outlook"</li>
      </ul>

      <p>
        This page informs you of our policies regarding the collection, use and
        disclosure of Personal Information when you use our Service.
      </p>

      <p>
        We will not use or share your information with anyone except as
        described in this Privacy Policy.
      </p>

      <p>
        We use your Personal Information for providing and improving the
        Service. By using the Service, you agree to the collection and use of
        information in accordance with this policy. Unless otherwise defined in
        this Privacy Policy, terms used in this Privacy Policy have the same
        meanings as in our Terms and Conditions, accessible at www.mashoom.co.uk
      </p>

      <h2>Information Collection And Use</h2>

      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. Personally identifiable information may include, but is
        not limited to, your email address, name, other information ("Personal
        Information").
      </p>

      <h2>Log Data</h2>

      <p>
        We may also collect information that your browser sends whenever you
        visit our Service ("Log Data"). This Log Data may include information
        such as your computer's Internet Protocol ("IP") address, browser type,
        browser version, the pages of our Service that you visit, the time and
        date of your visit, the time spent on those pages and other statistics.
      </p>

      <h2>Cookies</h2>

      <p>
        Cookies are files with small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a web
        site and stored on your computer's hard drive.
      </p>

      <p>
        We use "cookies" to collect information. You can instruct your browser
        to refuse all cookies or to indicate when a cookie is being sent.
        However, if you do not accept cookies, you may not be able to use some
        portions of our Service.
      </p>

      <h2>Service Providers</h2>

      <p>
        We may employ third party companies and individuals to facilitate our
        Service, to provide the Service on our behalf, to perform
        Service-related services or to assist us in analyzing how our Service is
        used.
      </p>

      <p>
        These third parties have access to your Personal Information only to
        perform these tasks on our behalf and are obligated not to disclose or
        use it for any other purpose.
      </p>

      <h2>Compliance With Laws</h2>

      <p>
        We will disclose your Personal Information where required to do so by
        law or in accordance with an order of a court of competent jurisdiction,
        or if we believe that such action is necessary to comply with the law
        and the reasonable requests of law enforcement or to protect the
        security or integrity of our Service.
      </p>

      <h2>Security</h2>

      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security. As such we make no warranties as to the
        level of security afforded to your data, except that we will always act
        in accordance with the relevant UK and EU legislation.
      </p>

      <h2>International Transfer</h2>

      <p>
        Your information, including Personal Information, may be transferred to
        - and maintained on - computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
      </p>

      <p>
        If you are located outside United Kingdom and choose to provide
        information to us, please note that we transfer the information,
        including Personal Information, to United Kingdom and process it there.
      </p>

      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>

      <p>
        In the event that a dispute arises with regards to the international
        transfer of data, you agree that the courts of England and Wales shall
        have exclusive jurisdiction over the matter.
      </p>

      <h2>Links To Other Sites</h2>

      <p>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit.
      </p>

      <p>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>

      <h2>Children's Privacy</h2>

      <p>
        Our Service does not address anyone under the age of 13 ("Children").
      </p>

      <p>
        We do not knowingly collect personally identifiable information from
        children under 13. If you are a parent or guardian and you are aware
        that your Children has provided us with Personal Information, please{" "}
        <IndyLink adr="/about/#ContactUs">contact us</IndyLink>. If we become
        aware that we have collected Personal Information from a child under age
        13 without verification of parental consent, we take steps to remove
        that information from our servers.
      </p>

      <h2>Changes To This Privacy Policy</h2>

      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>

      <p>
        If we make any material changes to this Privacy Policy, we will notify
        you either through the email address you have provided us, or by placing
        a prominent notice on our website.
      </p>

      <h2>Jurisdiction</h2>

      <p>
        This Policy shall be governed and construed in accordance with the laws
        of England and Wales, without regard to its conflict of law provisions.
      </p>

      <h1>Sub Processors</h1>
      <p>
        At Mashoom we use some carefully picked sub-processors that help us
        process your data. We carefully select these processors based on a range
        of criteria that ensure that from both a legal and practical perspective
        your data is as safe and as private as possible. We keep this list as
        short as possible only provide to them the minimum information possible
        for the activity they are performing. We have Data Processing Addendums
        in place with all of these providers where possible.
      </p>

      <p>Mashoom currently uses the following sub-processors:</p>
      <Table
        className={"SPTable"}
        data={[
          {
            "Sub-processor": (
              <IndyLink adr="https://aws.amazon.com">
                Amazon Web Services
              </IndyLink>
            ),
            "Service Provided": "Cloud service provider",
            "Location of Sub-processor": "Ireland",
          },
          {
            "Sub-processor": (
              <IndyLink adr="https://stripe.com">Stripe</IndyLink>
            ),
            "Service Provided": "Payment provider",
            "Location of Sub-processor": "United States of America",
          },
          {
            "Sub-processor": (
              <IndyLink adr="https://scanii.com/">Scanii</IndyLink>
            ),
            "Service Provided": "File virus scanning",
            "Location of Sub-processor": "Ireland",
          },
          {
            "Sub-processor": (
              <IndyLink adr="https://openai.com">OpenAI</IndyLink>
            ),
            "Service Provided": "Email parsing",
            "Location of Sub-processor": "United States of America",
          },
          {
            "Sub-processor": (
              <IndyLink adr="https://sentry.io">Sentry</IndyLink>
            ),
            "Service Provided":
              "Front end client error collection and profiling",
            "Location of Sub-processor": "United States of America",
          },
        ]}
      />

      <AnyQuestions />
    </ContentPage>
  )
}
