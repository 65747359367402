import React from 'react';

import style from './style.module.scss';

export function Table({data, className}){
    if (!Array.isArray(data)){
        throw new Error("Table data must be array of objects");
    }
    if (data.length == 0){
        return;
    }

    if (data[0].constructor !== Object){
        throw new Error("Table data must be array of objects in the format heading => value")
    }
    if (Object.keys(data[0]).length === 0){
        return;
    }

    // Get all headers
    let headers = [];
    let headerPrint = [];
    for (let rowI = 0; rowI < data.length; rowI++) {
        const row = data[rowI];
        for (const header in row) {
            if (row.hasOwnProperty(header) && !headers.includes(header)) {
                headers.push(header);
                headerPrint.push(<th key={header}>{header}</th>);
            }
        }
    }

    let rows = [];
    for (let rowI = 0; rowI < data.length; rowI++) {
        const row = data[rowI];
        let rowColsPrint = [];
        for (let headingI = 0; headingI < headers.length; headingI++) {
            const heading = headers[headingI];

            if (row.hasOwnProperty(heading)){
                rowColsPrint.push(<td key={heading+rowI}>{row[heading]}</td>);
            }else{
                rowColsPrint.push(<td key={heading+rowI}></td>);
            }
        }

        rows.push(<tr key={rowI}>{rowColsPrint}</tr>);
    }

    return (
        <table className={className + " " + style.simpleTable}>
            <thead><tr>{headerPrint}</tr></thead>
            <tbody>{rows}</tbody>
        </table>
    );
}